import styled, { keyframes } from "styled-components";

const openAnimation = keyframes`
    0% {
    transform: translateX(100px);
    visibility: hidden
    }
    100% {
    transform: translateX(0px);
    visibility: visible;
    }
`;


export const ToastWrapper = styled.div<{
    type: any;
  }>`
    position: fixed;
    right: 0;
    top: 0;
    margin: 48px 12px;
    padding: 10px 12px;
    border-radius: 12px;
    background-color: ${({type}) => maychColor(type)};
    animation: ${openAnimation} 0.1s linear;
    color: white;
    font-weight: bold;
    z-index: 9999;
    white-space: pre-wrap;
`;


function maychColor(type: any) {
    switch (type) {
        case "SUCCESS": 
            return 'rgb(255 108 158)';

        case "ERROR":  
            return '#e30d0d';
        
        default:
        return 'gray';
    }
}
import React from 'react';
import { RecoilRoot } from 'recoil';
import { Routes, Route, BrowserRouter } from 'react-router-dom';

import { PATH } from './paths';

import TopBar from '../component/layout/topBar';
import Footer from '../component/layout/footer';
import MobileProtectScreen from '../component/mobile-protect';

import { AuthContextProvider } from '../context/auth-context-provider';
import UseAuthPermit from '../hooks/use-auth-permit';
import isMobile from '../utils/new-is-mobile';

import { PageWrap, ContentWrap, LoadingContainer, Spinner } from './styled';
import UseUnAuthPermit from '../hooks/use-unAuth-permit';

export const renderRouter = () => {
  // if (!isMobile()) {
  //   return <MobileProtectScreen />;
  // }

  return (
    <BrowserRouter>
      <AuthContextProvider>
        <React.Suspense fallback={ 
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        }>
          <Routes>
            {routes.map((route, index) => {
              const Component = route.component;
              const Guard = route.guard || React.Fragment;
              return (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    <Guard key={index}>
                      <PageWrap>
                        <TopBar />
                        <ContentWrap className="contentWrap">
                          <RecoilRoot>
                              <Component />
                          </RecoilRoot>
                        </ContentWrap>
                        <Footer />
                      </PageWrap>
                    </Guard>
                  }
                />
              );
            })}
          </Routes>
        </React.Suspense>
      </AuthContextProvider>
    </BrowserRouter>
  );
};

const routes = [
  {
    guard: UseUnAuthPermit, 
    path: PATH.auth.signIn,
    component: React.lazy(()=> import('../app/auth/view/sign-in')),
  },  
  {
    guard: UseUnAuthPermit, 
    path: PATH.auth.signUp,
    component: React.lazy(()=> import('../app/auth/view/sign-up')),
  }, 
  {
    guard: UseUnAuthPermit, 
    path: PATH.auth.authenticate,
    component: React.lazy(()=> import('../app/auth/view/authenticate')),
  }, 
  {
    guard: UseAuthPermit, 
    path: PATH.home,
    component: React.lazy(()=> import('../app/home/main')),
  },
  // {
  //   guard: UseAuthPermit, 
  //   path: PATH.chat.detail,
  //   component: React.lazy(()=> import('../app/chat/view/detail')),
  // },
  // {
  //   guard: UseAuthPermit, 
  //   path: PATH.chat.letterIntro,
  //   component: React.lazy(()=> import('../app/chat/view/letter-intro')),
  // },
  {
    guard: UseAuthPermit, 
    path: PATH.chat.letterList,
    component: React.lazy(()=> import('../app/chat/view/letter-list')),
  },
  {
    guard: UseAuthPermit, 
    path: PATH.calendar.detail,
    component: React.lazy(()=> import('../app/calendar/view/main')),
  },
  {
    guard: UseAuthPermit, 
    path: PATH.maps.main,
    component: React.lazy(()=> import('../app/maps/view/main')),
  },
  {
    guard: UseAuthPermit, 
    path: PATH.community.main,
    component: React.lazy(()=> import('../app/community/view/main')),
  },
  {
    guard: UseAuthPermit, 
    path: PATH.profile.main,
    component: React.lazy(()=> import('../app/profile/view/main')),
  },
  // {
  //   path: PATH.profile.charge,
  //   component: React.lazy(()=> import('../app/profile/view/charge')),
  // },
  // {
  //   path: PATH.profile.chargeSuccess,
  //   component: React.lazy(()=> import('../app/profile/view/charge-success')),
  // },
  // {
  //   path: PATH.profile.chargeFail,
  //   component: React.lazy(()=> import('../app/profile/view/charge-fail')),
  // },
  {
    guard: UseAuthPermit, 
    path: '*',
    component: React.lazy(()=> import('../app/home/main')),
  },
  // {
  //   path: '*',
  //   component: React.lazy(() => import('../app/error')),
  // },
];

import { ActionType } from '.';

export type Toast = {
  type: "INFO" | "SUCCESS" | "ERROR";
  message: string;
  isOpen: boolean;
};

const toastInitState = {
  type: "INFO",
  message: '',
  isOpen: false,
};

const toastReducer = (state: Toast, action: ActionType) => {
  switch (action.type) {
    case 'OPEN_TOAST':
      return {
        ...state,
        isOpen: true,
        message: action.payload.message,
        type: action.payload.type
      };
  
    case 'CLOSE_TOAST':
      return {
        ...state,
        isOpen: false,
        message: '',
        type: 'INFO',
      };
      
    default:
      throw new Error();
  }
};

export { toastInitState, toastReducer };

// 해당 nav-reducers는 global-context-provider에서 사용된다

// import { useState, useDispatch } from '../../../Context/GlobalContextProvider';
// const {navBarState} = useState();

// const { navBarDispatch } = useDispatch();

// const closeNavBar = (e: any) =>{
//   navBarDispatch({type:'CHANGE_OPEN_STATUS', payload: false})
// }

import axios from 'axios';
import { AuthUserInfo } from '../model/userInfo';
import { AuthRepository } from '../repository';

export class AuthRepositoryImpl implements AuthRepository {
  private readonly http;

  constructor(http: any) {
    this.http = http;
  }

  async getCsrfToken(): Promise<string> {
    const csrfToken = await this.http.request('/auth/csrf', {
      method: 'GET',
    });
    return csrfToken;
    // const csrfToken = await axios.get('http://localhost/auth/csrf');
  }

  async getUserInfo(): Promise<{
    //유저PK
    id: string;
    //유저의 로그인Id
    userId: string;
    profile: any;
  }> {
    const res = await this.http.request('/user/me', {
      method: 'GET',
    });
    const { id, userId, profile } = res;

    return {
      id,
      userId,
      profile,
    };
  }

  async postSignUp(authUserInfo: AuthUserInfo) {
    const { id, password, eMail } = authUserInfo;

    await this.http.request('/user/signUp', {
      method: 'POST',
      body: {
        userId: id,
        userPassword: password,
        userEmail: `${eMail?.id}@${eMail?.domain}`,
      },
    });
  }

  async postSignIn(authUserInfo: AuthUserInfo): Promise<{
    //userId가 담겨있는 jwt토큰
    accessToken: string;
  }> {
    const { id, password } = authUserInfo;

    const res = await this.http.request('/user/signIn', {
      method: 'POST',
      body: {
        userId: id,
        userPassword: password,
      },
    });

    return res;
  }

  async getSignOut() {
    this.http.request('/user/signOut', {
      method: 'GET',
    });
  }

  async sendAuthenticateMailForgot(userId: any): Promise<any> {
    const res = await this.http.request('/auth/mail-forgot', {
      method: 'POST',
      body: {
        userId,
      },
    });

    return res;
  }

  async sendAuthenticateMailSignUp(userEmail: any): Promise<any> {
    const res = await this.http.request('/auth/mail-signUp', {
      method: 'POST',
      body: {
        userEmail,
      },
    });

    return res;
  }

  async checkAuthMailNumbers(
    userIdOrEmail: any,
    numbers: string
  ): Promise<any> {
    const res = await this.http.request('/auth/check-numbers', {
      method: 'POST',
      body: {
        userId: userIdOrEmail,
        numbers,
      },
    });

    return res;
  }

  async editAccountPassword(userId: any, password: string): Promise<any> {
    const res = await this.http.request('/auth/change-password', {
      method: 'POST',
      body: { userId, password },
    });

    return res;
  }
}

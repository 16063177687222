import { useTopBar } from './useTopBar';
import { PATH } from '../../../routes/paths';
import SearchResultListComponent from './search-result-list';

import {
  TopBarWrapper,
  SearchWrap,
  SvgWrap,
} from './styled';


const TopBarComponent = () => {
  const { 
    mapSearchState,
    isNonExposed, 
    isMapSearchExposed, 
    isOpenSearhResult,
    navigate, 
    changeInputSearch 
  } = useTopBar();

  if (isNonExposed) return null;

  return (
    <TopBarWrapper>
      <div>
        {!['/','/home'].includes(window.location.pathname) ? (
          <div 
            style={{ padding:'20px' }}
            onClick={() => navigate(-1)}
          >
            <svg
              style={{
                height:'100%'
              }}
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 448 512"
            >
              <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
            </svg>
          </div>
        ) : <div />}

        {isMapSearchExposed && (
          <SearchWrap className="search-room-bar-location-texts ">
            <input
              value={mapSearchState.inputValue}
              placeholder="원하는 위치 찾아주세요."
              onChange={changeInputSearch}
            />

            {isOpenSearhResult && (
              <SearchResultListComponent />
            )}
          </SearchWrap>
        )}

        <SvgWrap   
          onClick={() => {navigate(PATH.profile.main)}}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
            <circle cx="12" cy="7" r="4"></circle>
          </svg>
        </SvgWrap>
      </div>
    </TopBarWrapper>
  );
};

export default TopBarComponent;

import axios, { AxiosRequestConfig } from "axios";
import Socket from "../../../network/socket";
import { MapRepository } from "../repository";
import { SORT_TYPE } from "../view/main/state";

interface IRequestParam extends AxiosRequestConfig {
	body?: object;
}

export class MapRepositoryImpl implements MapRepository {
  private readonly http;
  private readonly socket: Socket;

  constructor(http: any, socket: Socket) {
    this.http = http;
    this.socket = socket;
  }

    async searchKeyword(keyword: string): Promise<any> {    
      const response = await this.http.request(`/map-point/search/searchKeyword`, {
        method: 'POST',
        body: { keyword }
      });

      return response;
    }

    async updateLikeOfPoint(mapPointId: string, isLike: boolean) {
      const response = await this.http.request(`/map-point/${mapPointId}/like`, {
        method: 'PUT',
        body: { isLike }
      });
      return response;
    }

    async postCreateMap(): Promise<any> {
        const response = await this.http.request('/map/add', {
            method: 'POST',
          });
      
        return response;
    }
    async postAddPoint(mapId: string, formData: FormData): Promise<any> {
        const response = await this.http.request(`/map-point/${mapId}`, {
            method: 'POST',
            body: formData,
            headers: { 'Content-Type': 'multipart/form-data' },
          });
      
          return response;
    }

    async editPoint(mapId: string, pointId: string, formData: FormData): Promise<any> {
      const response = await this.http.request(`/map-point/${mapId}/edit/${pointId}`, {
          method: 'PUT',
          body: formData,
          headers: { 'Content-Type': 'multipart/form-data' },
        });
    
        return response;
  }

  async deletePoint(mapId: string, pointId: string): Promise<any> {
    const response = await this.http.request(`/map-point/${mapId}/delete/${pointId}`, {
      method: 'DELETE',
    });

  return response;
}

    async getPointList(mapId: string, sortType: SORT_TYPE): Promise<any> {
        const response = await this.http.request(`/map-point/map/${mapId}?sortType=${sortType}`, {
            method: 'GET',
          });
      return response;
    }

    async getPointInfo(pointId: string): Promise<any> {
      const response = await this.http.request(`/map-point/${pointId}`, {
          method: 'GET',
        });
    return response;
  }

  listenEvent(event: string, callback: any) {
    return this.socket.listenEvent(event, callback)
  }

  emitEvent(event: string, data: any, callback: any) {
    //callback을 통해 emit시
    //listen하고있는 server측의 핸들링메소드의 return값을 넘겨받을수있다!
    return this.socket.emitEvent(event, data, callback);
  }
 }
 
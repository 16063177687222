import Axios, { AxiosError, AxiosRequestConfig } from 'axios';

interface IRequestParam extends AxiosRequestConfig {
  body?: object;
}

const live = 'https://api.lovmory.com';
const dev = 'http://localhost';
export class HttpClient {
  getCsrfToken: any;
  axios = Axios.create({
    baseURL: live,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
  });

  constructor(getCsrfToken: any) {
    this.getCsrfToken = getCsrfToken;
  }

  async request(url: string, options: IRequestParam) {
    const { body, method, headers } = options;
    const req = {
      url,
      method,
      headers: {
        ...headers,
        'greenary-csrf-token': this.getCsrfToken().csrfToken,
        //이것은 로컬에서는 도메인이 다르면 쿠키설정을 못해줘서 임의로 로컬스토리지로 대체한거지
        //나중에 배포하면 jwt토큰 로컬스토리지에 담아놓지 않을것임
        //서버에서의 res.cookie로 설정해줄것이다!! 임시일뿐이다.
        // Authorization: `Bearer ${window.localStorage.getItem('greenary-jwt')}`,
      },
      data: body,
    };

    //axios는 자동으로 json으로 변환까지도 해준다.
    try {
      const res = await this.axios(req);
      return res.data;
    } catch (err) {
      const { response } = err as AxiosError;

      //network error가 발생했거나, status code가 200~ 대가 아니면 catch로 들어오게된다.
      if (response) {
        const data: any = response.data;

        if (
          data.message === 'Unauthorized' &&
          data.statusCode === 401 &&
          !window.location.pathname.includes('sign-in')
        )
          return window.location.replace('/auth/sign-in');

        const message =
          data && data.hasOwnProperty('message')
            ? data.message
            : 'Something went wrong!';

        throw new Error(message);
      }

      //네트워크 에러로 인한 에러
      throw new Error('connection Error!');
    }
  }
}

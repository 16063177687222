import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuthState } from '../context/auth-context-provider';
import { PATH } from '../routes/paths';
import { useCookies } from 'react-cookie';

interface Props {
  children: ReactNode;
}

const UseAuthPermit = ({ children }: Props) => {
  const navigate = useNavigate();
  const { userInfo } = useAuthState();
  const [cookies] = useCookies();

  React.useEffect(() => {
    //나중에 cookie기반으로 다 바뀌면 로컬스토리지 로직은 뺴라 필요없다!
    if (!cookies['greenary-jwt']) {
      //replace true로 해줘야 닫았을때 기존에 보고있던 화면을 다시 노출해줄수있다
      navigate(PATH.auth.signIn, { replace: true });
      // navigate(PATH.home);
    }
  }, [navigate, userInfo?.id]);

  if (!userInfo?.id) {
    return null;
  }

  return <>{children}</>;
};

export default UseAuthPermit;

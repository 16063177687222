import { AuthUserInfo } from '../model/userInfo';
import { AuthRepository } from '../repository';

export class AuthUseCase {
  private readonly authRepository: AuthRepository;

  constructor(authRepository: AuthRepository) {
    this.authRepository = authRepository;
  }

  //csrf토큰받기
  async getCsrfToken(): Promise<string> {
    const res = await this.authRepository.getCsrfToken();
    return res;
  }

  //유저정보받기
  async getUserInfo(): Promise<{
    //유저PK
    id: string;
    //유저의 로그인Id
    userId: string;
  }> {
    const res = await this.authRepository.getUserInfo();
    return res;
  }

  //회원가입하기
  async signUp({ id, password, eMail }: AuthUserInfo) {
    await this.authRepository.postSignUp({
      id,
      password,
      eMail,
    });
  }

  //로그인하기
  async signIn({ id, password }: AuthUserInfo): Promise<{
    //userId가 담겨있는 jwt토큰
    accessToken: string;
  }> {
    const res = await this.authRepository.postSignIn({
      id,
      password,
    });

    return res;
  }

  //로그아웃하기
  async signOut() {
    this.authRepository.getSignOut();
  }

  //비밀번호 찾기 인증메일보내기
  async sendAuthMailForgot(userId: any) {
    await this.authRepository.sendAuthenticateMailForgot(userId);
  }

  //회원가입 인증메일 요청하기
  async sendAuthMailSignUp(userEmail: any) {
    await this.authRepository.sendAuthenticateMailSignUp(userEmail);
  }

  //인증메일 검증
  async checkAuthMailNumbers(userIdOrEmail: any, numbers: string) {
    await this.authRepository.checkAuthMailNumbers(userIdOrEmail, numbers);
  }

  //비밀번호 바꾸기
  async editAccountPassword(userId: any, password: string) {
    await this.authRepository.editAccountPassword(userId, password);
  }
}

import { MapRepository } from "../repository";
import { SORT_TYPE } from "../view/main/state";

export class MapUseCase {
  private readonly mapRepository: MapRepository;

  constructor(mapRepository: MapRepository) {
    this.mapRepository = mapRepository;
  }

  async createMap(){
    const response = await this.mapRepository.postCreateMap();
    return response;
  }
  
  async addMapPoint(mapId: string, formData: FormData){
    const response = await this.mapRepository.postAddPoint(mapId,formData);
    return response;
  }

  async editMapPoint(mapId: string, pointId: string, formData: FormData){
    const response = await this.mapRepository.editPoint(mapId, pointId, formData);
    return response;
  }

  async deleteMapPoint(mapId: string, pointId: string){
    const response = await this.mapRepository.deletePoint(mapId, pointId);
    return response;
  }
  // async editSchedule(calendarId: string,scheduleId: string, formData: FormData){
  //   const response = await this.calendarRepository.putEditSchedule(calendarId, scheduleId,formData);
  //   return response;
  // }

  async getMapPointList(mapId: string, sortType: SORT_TYPE) {
    const response = await this.mapRepository.getPointList(mapId, sortType)
    return response;
  }
  
  async getDetailPointInfo(pointId: string) {
    const response = await this.mapRepository.getPointInfo(pointId);
    return response;
  }
  
  //맵 Point 좋아요 누르기
  async updateMapPointLikeStatus(mapPointId: string, isLike: boolean) {
      return this.mapRepository.updateLikeOfPoint(mapPointId, isLike)
  }

  async searchKeyword(keyword: string) {
    return this.mapRepository.searchKeyword(keyword);
  }

  listenEvent(event: string, callback: any) {
    return this.mapRepository.listenEvent(event, callback)
  }

  emitEvent(event: string, data: any, callback: any) {
    return this.mapRepository.emitEvent(event, data, callback);
  }
 } 
 
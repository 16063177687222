import React, { createRef, ReactNode, useContext, useImperativeHandle, useReducer} from 'react'
import { useNavigate } from 'react-router-dom';

import { AuthType, authInitState, authReducer } from './auth-reducer'

import { authUseCase } from '../../app/auth/di';

import { PATH } from '../../routes/paths';

const ContextState = React.createContext<AuthType | null>(null);

export type ActionType = { type?: string; payload?: any; }

const ContextDispatch = React.createContext< React.Dispatch<ActionType> | null>(null);

interface Props {
  children: ReactNode
}

const csrfRef = createRef();
const jwtTokenRef = createRef();

export const AuthContextProvider = ({ children }: Props) => {
  const navigate = useNavigate();
    const [authState, authDispatch] = useReducer(authReducer, authInitState);
    
    useImperativeHandle(csrfRef, () => authState); 
    useImperativeHandle(jwtTokenRef, () => authState.userInfo ? authState.userInfo.token : undefined); 

    React.useEffect(() => {
      initCsrf();
    }, [])

    React.useEffect(() => {
      if (authState.csrfToken) {
        initUserInfo();
      }
    }, [authState.csrfToken])


    async function initCsrf() {
      const csrfres = await authUseCase.getCsrfToken();
      authDispatch({type:'UPDATE_CSRF_TOKEN', payload: csrfres})
    }

    async function initUserInfo() {
      try {
        // const result = await authUseCase.signIn({id: '거니',password: '123'});
        const userInfores = await authUseCase.getUserInfo()
        authDispatch({type:'UPDATE_USER_INFO', payload: userInfores})
      } catch (error) {
        if (error instanceof Error) {
          if (error?.message === 'Unauthorized') {
            console.log('로그아웃처리해야되는 부분이다람쥐포도!')
            await authUseCase.signOut();
            setTimeout(function(){
              navigate(PATH.auth.signIn)
            }, 100);
          }
        }
      }
    }

    //이런식으로 처리하면 존재하지 않는 경로는 싹다 SignInScreen로 보내줄있음
    // if (!authState.user ) {
    //   console.log('뻄!')
    //   return (
    //     <div>
    //       <SignInScreen />
    //     </div>
    //   )  
    // }

    return(
        <ContextState.Provider value={authState}>
            <ContextDispatch.Provider value={authDispatch}>
                {children}        
            </ContextDispatch.Provider>
        </ContextState.Provider>
    )
}

export const getCsrfToken = () => csrfRef.current;  
export const getJwtToken = () => jwtTokenRef.current;

export const useAuthState = () => {
    const state = useContext(ContextState);
    if (!state) throw new Error('cannot find state')
    return state;
}

export const useAuthDispatch = () => {
    const dispatch = useContext(ContextDispatch);
    if (!dispatch) throw new Error('cannot find Dispatch')
    return dispatch;
}

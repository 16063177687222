import { ActionType } from '.';

export interface Item {
  category: string;
  title: string;
  address: string; 
  roadAddress: string;
  lat: number;
  lng: number;
  link: string;
}

export type MapSearch = {
  inputValue: string;
  result: {
    display: number;
    items: Item[];
    total: number;
  } | null;

  targetItem: Item | null;
}

const mapSearchInitState: MapSearch = {
  inputValue: '',
  result: null,
  targetItem: null,
};

const mapSearchReducer = (state: MapSearch, action: ActionType) => {
  switch (action.type) {
    case 'CHANGE_INPUT':
      return {
        ...state,
        inputValue: action.payload.inputValue,
      };

    case 'CHANGE_RESULT':
      return {
        ...state,
        result: action.payload.result,
      };

    case "SET_TARGET_ITEM":
      return {
        ...state,
        targetItem: action.payload.target,
      };
  
    case "INIT":
      return {
        ...mapSearchInitState
      };
      
    default:
      throw new Error();
  }
};

export { mapSearchInitState, mapSearchReducer };

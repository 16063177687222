type PathType = {
  auth: {
    signIn: string;
    signUp: string;
    authenticate: string;
  };
  home: string;
  chat: {
    detail: string;
    letterIntro: string;
    letterList: string;
  };
  calendar: {
    detail: string;
  };
  maps: {
    main: string;
  };
  community: {
    main: string;
  };
  profile: {
    main: string;
    charge: string;
    chargeSuccess: string;
    chargeFail: string;
  };
  store: {
    list: string;
    detail: string;
    register: string; //StoreAuth
  };
};

export const PATH: PathType = {
  auth: {
    signIn: '/auth/sign-in',
    signUp: '/auth/sign-up',
    authenticate: '/auth/authenticate',
  },
  home: '/home',
  chat: {
    detail: '/chat/:id/',
    letterIntro: '/letterIntro/:id',
    letterList: '/letterList/:id',
  },
  calendar: {
    detail: '/calendar/:id',
  },
  maps: {
    main: '/maps/:id',
  },
  community: {
    main: '/community',
  },
  profile: {
    main: '/profile',
    charge: '/profile/charge',
    chargeSuccess: '/profile/charge/success',
    chargeFail: '/profile/charge/fail',
  },
  store: {
    list: '/store/:category?/list',
    detail: '/store/:category?/:id/',
    register: '/store/:category?/:id?/register/:tab(add|edit)?',
  },
};

import { useEffect } from "react";
import { useGlobalDispatch, useGlobalState } from "../../context/global-context-provider";
import { ToastWrapper } from "./styled";

const ToastComponent = () => {
    const {toastState}= useGlobalState()
    const { toastDispatch } = useGlobalDispatch()
 
    useEffect(() => {
        if(toastState.isOpen) {
            setTimeout(() => {
                toastDispatch({ type:'CLOSE_TOAST' })
            }, 2400);
        }
    }, [toastState.isOpen])

    if (toastState.isOpen) {
        return (
            <ToastWrapper type={toastState.type}>
                {toastState.message ?? ''}
            </ToastWrapper>
        )
    }

    return null;

}

export default ToastComponent;
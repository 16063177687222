import React, { ReactNode, useContext, useReducer } from 'react';
import { NavBarType, navBarInitState, navBarReducer } from './nav-reducer';
import { Toast, toastInitState, toastReducer } from './toast-reducer';
import { MapSearch, mapSearchInitState, mapSearchReducer } from './map-search-reducer';

//State
type ContextStateType = {
  navBarState: NavBarType;
  toastState: Toast;
  mapSearchState: MapSearch;
};

const ContextState = React.createContext<ContextStateType | null>(null);

//Dispatch
export type ActionType = { type?: string; payload?: any };

type ContextDispatchType = {
  navBarDispatch: React.Dispatch<ActionType>;
  toastDispatch: React.Dispatch<ActionType>;
  mapSearchDispatch: React.Dispatch<ActionType>;
};

const ContextDispatch = React.createContext<ContextDispatchType | null>(null);

interface Props {
  children: ReactNode;
}

export const GlobalContextProvider = ({ children }: Props) => {
  const [navBarState, navBarDispatch] = useReducer(
    navBarReducer,
    navBarInitState
  );

  const [toastState, toastDispatch] = useReducer( toastReducer, toastInitState )

  const [mapSearchState, mapSearchDispatch] = useReducer( mapSearchReducer, mapSearchInitState )
  //다른 리듀서가 추가되면 여기에 똑같이 선언해주면된다.
  return (
    <ContextState.Provider value={{ navBarState, toastState, mapSearchState }}>
      <ContextDispatch.Provider value={{ navBarDispatch, toastDispatch, mapSearchDispatch }}>
        {children}
      </ContextDispatch.Provider>
    </ContextState.Provider>
  );
};

export const useGlobalState = () => {
  const state = useContext(ContextState);
  if (!state) throw new Error('cannot find state');
  return state;
};

export const useGlobalDispatch = () => {
  const dispatch = useContext(ContextDispatch);
  if (!dispatch) throw new Error('cannot find Dispatch');
  return dispatch;
};

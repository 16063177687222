import styled, { keyframes } from 'styled-components';

export const PageWrap = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

export const ContentWrap = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`;

// 애니메이션 정의
const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

// 스타일이 적용된 컴포넌트
export const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid rgb(255, 50, 108);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: ${spin} 1s linear infinite;
  display: inline-block;
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: pink;
  height: 100vh;
`;

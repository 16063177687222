import styled from "styled-components";

export const ListWrap = styled.div`
    position: absolute;
    max-height: 312px;
    border: 1px solid #d4d4d4;
    background-color: white;
    box-shadow: 0 10px 20px rgba(0,0,0,0.2);
    overflow: hidden;
    top: 100%;
    left: 0;
    right: 0;
    transform: translateY(12px);
    z-index: 9 !important;
    border-radius: 4px;
    padding: 16px 8px;
    overflow-y: auto;
`;

export const Item = styled.div`
    & + & {
        border-top: 1px solid gray;
    }
    padding: 12px 8px;
    > p {
        margin: 0;
        white-space: pre-wrap;
        word-break: break-all;
    }

    > a {
        white-space: pre-wrap;
        word-break: break-all;
    }
`;

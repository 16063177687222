import React from 'react';

import {
  Container,
  HomeIconWrap,
  BucketIconWrap,
  StoreIconWrap,
  FavorIconWrap,
  GroupIconWrap,
} from './styled';

import { useFooterBar } from './useFooterBar';
import path from 'path';
import { PATH } from '../../../routes/paths';
import AdvertisementSwipeComponent from '../../advertisement';

const FooterComponent = () => {
  const { isNonExposed, isAdvertiseExposed, navigate } = useFooterBar();

  if (isNonExposed()) return null;

  return (
    <Container>
      <div className="content">
        <BucketIconWrap>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path  fill='rgb(255 6 110 / 69%)' d="M96 152v8H48v-8C48 68.1 116.1 0 200 0h48c83.9 0 152 68.1 152 152v8H352v-8c0-57.4-46.6-104-104-104H200C142.6 48 96 94.6 96 152zM0 224c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32h-5.1L388.5 469c-2.6 24.4-23.2 43-47.7 43H107.2c-24.6 0-45.2-18.5-47.7-43L37.1 256H32c-17.7 0-32-14.3-32-32z" />
          </svg>
        </BucketIconWrap>

        <StoreIconWrap
          onClick={() => {
            // navigate('/my/account');
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
            <path  fill='rgb(255 6 110 / 69%)' d="M547.6 103.8L490.3 13.1C485.2 5 476.1 0 466.4 0H109.6C99.9 0 90.8 5 85.7 13.1L28.3 103.8c-29.6 46.8-3.4 111.9 51.9 119.4c4 .5 8.1 .8 12.1 .8c26.1 0 49.3-11.4 65.2-29c15.9 17.6 39.1 29 65.2 29c26.1 0 49.3-11.4 65.2-29c15.9 17.6 39.1 29 65.2 29c26.2 0 49.3-11.4 65.2-29c16 17.6 39.1 29 65.2 29c4.1 0 8.1-.3 12.1-.8c55.5-7.4 81.8-72.5 52.1-119.4zM499.7 254.9l-.1 0c-5.3 .7-10.7 1.1-16.2 1.1c-12.4 0-24.3-1.9-35.4-5.3V384H128V250.6c-11.2 3.5-23.2 5.4-35.6 5.4c-5.5 0-11-.4-16.3-1.1l-.1 0c-4.1-.6-8.1-1.3-12-2.3V384v64c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V384 252.6c-4 1-8 1.8-12.3 2.3z" />
          </svg>
        </StoreIconWrap>

        <HomeIconWrap
          onClick={() => {
            navigate(PATH.home, { replace: true });
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
            <path fill='rgb(255 6 110 / 69%)' d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z" />
          </svg>
        </HomeIconWrap>

        <FavorIconWrap>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
            <path fill='rgb(255 6 110 / 69%)' d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" />
          </svg>
        </FavorIconWrap>

        <GroupIconWrap>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
            <path fill='rgb(255 6 110 / 69%)' d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM609.3 512H471.4c5.4-9.4 8.6-20.3 8.6-32v-8c0-60.7-27.1-115.2-69.8-151.8c2.4-.1 4.7-.2 7.1-.2h61.4C567.8 320 640 392.2 640 481.3c0 17-13.8 30.7-30.7 30.7zM432 256c-31 0-59-12.6-79.3-32.9C372.4 196.5 384 163.6 384 128c0-26.8-6.6-52.1-18.3-74.3C384.3 40.1 407.2 32 432 32c61.9 0 112 50.1 112 112s-50.1 112-112 112z" />
          </svg>
        </GroupIconWrap>
      </div>

      {/* <div>
        {isAdvertiseExposed() && <AdvertisementSwipeComponent />}
        <p style={{
          maxWidth:'680px',
          margin: '0 auto',
          display:'flex',
          justifyContent:'flex-end',
          padding: '12px 8px'
        }}>
          고객지원 및 광고문의&nbsp;
          <span>greenwhales777@gmail.com</span>
        </p>
      </div> */}
    </Container>
  );
};

export default FooterComponent;

import { useEffect } from "react";
import { useGlobalDispatch, useGlobalState } from "../../../../context/global-context-provider";
import { Item } from "../../../../context/global-context-provider/map-search-reducer";

export function useSearch() {
    const { mapSearchState } = useGlobalState();
    const { mapSearchDispatch } = useGlobalDispatch();

    useEffect(() => {
        return () => {
            mapSearchDispatch({ type:'INIT' });
        }
    }, [])

    function clickItem(target: Item) {
        mapSearchDispatch({
            type:'SET_TARGET_ITEM', 
            payload: { 
                target,
            }
        });
    }

    return {
        mapSearchState,
        clickItem,
    }
}

import { ChangeEvent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useDebounce from '../../../hooks/useDebounce';
import { useGlobalDispatch, useGlobalState } from '../../../context/global-context-provider';
import { mapUseCase } from '../../../app/maps/di';

export function useTopBar() {
  const navigate = useNavigate();
  const { mapSearchState } = useGlobalState();
  const { mapSearchDispatch } = useGlobalDispatch();
  
  const nonExposedList = ['/auth/sign-in', '/auth/sign-up', '/auth/authenticate'];
  
  const isNonExposed = nonExposedList.includes(window.location.pathname);
  const isMapSearchExposed = window.location.pathname.includes('/maps');

  const searchkeyword = useDebounce(mapSearchState.inputValue, 1000);

  useEffect(() => {
    if (isMapSearchExposed && !!mapSearchState.inputValue) {
      getSerchKeywordList();
    }
  }, [searchkeyword]);

  async function getSerchKeywordList() {
    try {
      const result = await mapUseCase.searchKeyword(searchkeyword);
      mapSearchDispatch({
        type:'CHANGE_RESULT', 
        payload: { 
          result,
        }
      });

    } catch (error) {
      console.log(error);
    }
  }

  function changeInputSearch(e: ChangeEvent<HTMLInputElement>) {
    mapSearchDispatch({
      type:'CHANGE_INPUT', 
      payload: { 
        inputValue: e.target.value,
      }
    });
  }

  const isOpenSearhResult = !!mapSearchState.inputValue;

  return { 
    mapSearchState,
    isNonExposed, 
    isMapSearchExposed,
    isOpenSearhResult,
    navigate,
    changeInputSearch 
  };
}

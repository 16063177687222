import { Item, ListWrap } from "./styled";
import { useSearch } from "./useSearch";

const SearchResultListComponent = () => {
    const { mapSearchState, clickItem } = useSearch();
    const isLoading = mapSearchState.result === null && mapSearchState.inputValue.length > 0;
    const NoData =  (mapSearchState.result && mapSearchState.result?.items.length === 0) && mapSearchState.inputValue.length > 0;
    return (
        <ListWrap>
            {isLoading ? (
                <Item>로딩중...</Item>
            ) : NoData ? (
                <Item>데이터 없음</Item>
            ): (
                <div>
                {mapSearchState.result?.items.map((value, idx) => (
                    <Item key={idx} onClick={clickItem.bind(null, value)}>
                        <p>{value.category}</p>
                        <p dangerouslySetInnerHTML={{__html: value.title}}/>
                        <p>{value.roadAddress}</p>    
                        <p>{value.address}</p>
                        {/* <a href={value.link} target="_blank">
                            {value.link}
                        </a> */}
                    </Item>
                ))}
            </div>
            )}
            
        </ListWrap>
    )
}

export default SearchResultListComponent;

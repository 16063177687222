import { getCsrfToken } from "../../../context/auth-context-provider";
import { HttpClient } from "../../../network/axios";
import Socket from "../../../network/socket";
import { MapRepositoryImpl } from "../repository/map.repository";

import { MapUseCase } from "../usecase/map-usecase";

const http = new HttpClient(() => getCsrfToken());
const socketClient = new Socket(()=> getCsrfToken());

const mapRepositoryImpl = new MapRepositoryImpl(http, socketClient);
const mapUseCase = new MapUseCase(mapRepositoryImpl);

export  {
  mapUseCase,
}

import React, { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom';
import { useAuthState } from '../context/auth-context-provider';
import { PATH } from '../routes/paths';
import {Cookies} from 'react-cookie';
import { userInfoAtom } from '../app/auth/view/sign-in/state';

interface Props {
  children: ReactNode
}

const UseUnAuthPermit = ({ children }: Props) => {
  const navigate = useNavigate();
  const cookies = new Cookies();
  // const [ cookies ] = useCookies(['greenary-jwt']);     
  const { userInfo } = useAuthState();
  
  React.useEffect(() => {
    if (!!cookies.get('greenary-jwt')) {
      // console.log(cookies,'cookiescookies')
      // // console.log(cookies['greenary-jwt'],'greenary-jwtgreenary-jwt')
      navigate(PATH.home);
    }
  }, [navigate, userInfo?.id])

  return (
    <>
      {children}
    </>
  )
}

export default UseUnAuthPermit;
